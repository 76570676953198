import React from "react"
import Layout from "../components/Layout/Layout"
import {Link} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";
import Kontakt from "../components/Layout/Contact";
import CarouselReference from "../components/Components/CarouselReference";

export default function Onas() {
    return (
        <Layout>
            <div className="aboutus">
                <div className="headerBluePic mb-4">
                    <div className="headerBlue_content">
                        <div className="container ps-5 pe-5">
                            <h1 className="heading-border-bottom h2 d-lg-none d-block pt-5 text-center">O nás</h1>
                            <div className="row pt-4">
                                <div className="col-12 col-lg-6 order-lg-1 order-2 pt-5 pb-md-5">
                                    <h1 className="heading-border-bottom h2 d-none d-lg-block">O nás</h1>

                                    <p className="subHeadline pt-1 text-center text-lg-start">Digi Office je ryze česká
                                        IT
                                        firma s 15 letou historií a praktickými zkušenostmi. Děláme IT a technologie pro
                                        malé a středně velké firmy a organizace. Poskytneme Vám odborné know-how,
                                        zastupitelnost techniků, pojištění odpovědnosti či NDA. Máme vybudovány
                                        partnerské
                                        vztahy s předními technologickými firmami.</p>
                                </div>
                                <div className="col-12 col-lg-6 order-lg-2 order-1">
                                    <div className="mx-auto pt-lg-5 p-xxl-5">
                                        <StaticImage layout={'fullWidth'} imgClassName={"onas"}
                                                     alt={"Tým"}
                                                     src="../assets/custom-style/images/digi/_NG_8377_ACR.jpg"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="bgWhite pt-5 pb-5">
                    <div className="container">
                        <p className="text-center">Přinášíme Vám řešení pro práci s informacemi, sdílení, komunikaci a
                            spolupráci, navíc vše zabezpečíme a nastavíme tak, abyste ho mohli využívat v práci, doma i
                            na
                            cestách. Dokážeme se o Vás postarat komplexně formou Outsourcingu, tedy převzetím správy
                            celého
                            Vašeho IT.</p>
                    </div>
                </div>

                <div className="bgGrey pt-5 pb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 d-none d-md-block ps-xl-5 pe-xl-5">

                                <StaticImage layout={'fullWidth'}
                                             src="../assets/custom-style/images/digi/latal-1.jpg"
                                             alt={"Jaroslav Látal"}/>


                            </div>
                            <div className="col-md-8 ps-md-5">
                                <h1 className="h3 text-center text-md-start">Jaroslav Látal</h1>
                                <p className="small text-muted text-center text-md-start">Společník a jednatel Digi
                                    office,
                                    s.r.o.</p>
                                <div className="d-xs-block d-md-none pb-5">
                                    <StaticImage layout={'fullWidth'}
                                                 className={'mobile-circle'}
                                                 src="../assets/custom-style/images/digi/latal-2.jpg"
                                                 alt={"Jaroslav Látal"}/>

                                </div>

                                <div className="text-center text-md-start timeline">
                                    <div className="timeline-item">
                                        <div className={"row"}>
                                            <h3 className="col-lg-2 col-md-3 col-12 fw-normal text-secondary">2002</h3>
                                            <p className="col-lg-10 col-md-9 col-12 small">Svoji kariéru jsem začínal ve
                                                velké IT
                                                společnosti, pro kterou
                                                jsem pracoval více než 5 let. Postupně jsem začal chápat, že dobře
                                                spravované
                                                servery nebo cloud není to nejdůležitější, a že klienti hlavně potřebují
                                                pomoct
                                                s propojením IT a jejich byznysu.</p>
                                        </div>
                                    </div>

                                    <div className="timeline-item">
                                        <div className={"row"}>
                                            <h3 className="col-lg-2 col-md-3 col-12 fw-normal text-secondary">2007</h3>
                                            <p className="col-lg-10 col-md-9 col-12 small">Začal jsem pracovat jako OSVČ
                                                právě v oblasti správy IT. S
                                                vědomím, že nemá cenu vymýšlet to, co už bylo vymyšleno, jsem se
                                                postupně
                                                vzdělával v oblasti metodik IT a jejich aplikace na malé firmy. Postupně
                                                přibývali noví klienti, takže jsem našel parťáka, který mi pomáhal.</p>
                                        </div>
                                    </div>
                                    <div className="timeline-item">
                                        <div className={"row"}>
                                            <h3 className="col-lg-2 col-md-3 col-12 fw-normal text-secondary">2012</h3>
                                            <p className="col-lg-10 col-md-9 col-12 small">Práce bylo čím dál tím více,
                                                začali jsme pracovat i pro
                                                větší
                                                klienty a bylo tedy na čase založit firmu Digi Office, s.r.o. a postupně
                                                budovat
                                                tým techniků.</p>
                                        </div>
                                    </div>

                                    <div className="timeline-item">
                                        <div className={"row"}>
                                            <h3 className="col-lg-2 col-md-3 col-12 fw-normal text-secondary">2015</h3>
                                            <p className="col-lg-10 col-md-9 col-12 small">Přestěhovali jsme se z malé
                                                kanceláře do stávajících
                                                prostor, kde
                                                máme kromě kanceláří dostatek místa i pro schůzky, sklad a dílnu.</p>
                                        </div>
                                    </div>

                                    <div className="timeline-item">
                                        <div className={"row"}>
                                            <h3 className="col-lg-2 col-md-3 col-12 fw-normal text-secondary">2017</h3>
                                            <p className="col-lg-10 col-md-9 col-12 small">Vytvořili jsme vlastní
                                                směrnici
                                                pro provoz IT, která je
                                                určena pro
                                                malé a středně velké firmy a organizace. Vychází z metodiky ITIL, normy
                                                ISO
                                                27001 a
                                                prošla auditem od společnosti DATASYS s.r.o.</p>
                                        </div>
                                    </div>

                                    <div className="timeline-item">
                                        <div className={"row"}>
                                            <h3 className="col-lg-2 col-md-3 col-12 fw-normal text-secondary">2018</h3>
                                            <p className="col-lg-10 col-md-9 col-12 small">Nasadili jsme novou verzi
                                                ServiceDesk aplikace, díky které
                                                jsme
                                                snížili reakční dobu a výrazně zefektivnili vnitřní fungování naší
                                                technické
                                                podpory.</p>
                                        </div>
                                    </div>

                                    <div className="timeline-item">
                                        <div className={"row"}>
                                            <h3 className="col-lg-2 col-md-3 col-12 fw-normal text-secondary">2020</h3>
                                            <p className="col-lg-10 col-md-9 col-12 small">Zjednodušili jsme nabídku
                                                služeb, standardizovali zákaznickou dokumentaci a vyladili interní
                                                procesy. Zároveň jsme přidali službu IT manažera, jehož roli považujeme
                                                za klíčovou pro efektivní fungování IT.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="timeline-item">
                                        <div className={"row"}>
                                            <h3 className="col-lg-2 col-md-3 col-12 fw-normal text-secondary">2022</h3>
                                            <p className="col-lg-10 col-md-9 col-12 small">Spustili jsme nový web a jsme
                                                připraveni pomoci novým klientům vybudovat a spravovat IT, které Váš
                                                byznys potřebuje.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bgBlue pt-5 pb-5">
                    <div className="container">
                        <div className="row">

                            <div className="col-sm text-center">
                                <div className="numbers">
                                    {/*<CountUp delay={1} duration={5} start={1} end={10} enableScrollSpy />+*/}
                                    10+
                                </div>
                                <p className="small">let na trhu se stále <br/>stejným zaměřením</p>
                            </div>
                            <div className="col-sm text-center">
                                <div className="numbers">
                                    {/*<CountUp delay={1} duration={5} start={1} end={40} enableScrollSpy/>+*/}
                                    40+
                                </div>
                                <p className="small">firemním zákazníkům <br/>spravujeme IT</p>
                            </div>
                            <div className="col-sm text-center">
                                <div className="numbers">
                                    {/*<CountUp delay={0} duration={6} start={1} end={5000}  enableScrollSpy/>+*/}
                                    5000+
                                </div>
                                <p className="small">vyřešených požadavků <br/>za rok 2021</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="nasTym bgWhite pt-5 pb-5">
                    <div className="container pt-3 pb-5">
                        <h2 className="text-center mb-4">Náš tým</h2>
                        <div className="row pt-3">
                            <div className="col-md">
                                <figure className="d-none d-md-block">
                                    <StaticImage layout={'fullWidth'}
                                                 src="../assets/custom-style/images/digi/_NG_8293_ACR-cropped.jpeg"
                                                 alt={"Jaroslav Látal"}/>
                                    <figcaption className="figure-caption pt-1">Jaroslav Látal</figcaption>
                                </figure>
                                <figure className="d-block d-md-none">
                                    <StaticImage layout={'fullWidth'}
                                                 src="../assets/custom-style/images/digi/_NG_8293_ACR.jpg"
                                                 alt={"Jaroslav Látal"}/>
                                    <figcaption className="figure-caption pt-1 text-center">Jaroslav Látal</figcaption>
                                </figure>
                            </div>

                            <div className="col-md">
                                <figure className="d-none d-md-block">
                                    <StaticImage layout={'fullWidth'}
                                                 src="../assets/custom-style/images/digi/_NG_8240_ACR-cropped.jpeg"
                                                 alt={"Tomáš Pokorný"}/>
                                    <figcaption className="figure-caption pt-1">Tomáš Pokorný</figcaption>
                                </figure>
                                <figure className="d-block d-md-none">
                                    <StaticImage layout={'fullWidth'}
                                                 src="../assets/custom-style/images/digi/_NG_8240_ACR.jpg"
                                                 alt={"Tomáš Pokorný"}/>
                                    <figcaption className="figure-caption pt-1 text-center">Tomáš Pokorný</figcaption>
                                </figure>
                            </div>

                            <div className="col-md">
                                <figure className="d-none d-md-block">
                                    <StaticImage layout={'fullWidth'}
                                                 src="../assets/custom-style/images/digi/_NG_8326_ACR-cropped.jpeg"
                                                 alt={"Petr Bláha"}/>
                                    <figcaption className="figure-caption pt-1">Petr Bláha</figcaption>
                                </figure>
                                <figure className="d-block d-md-none">
                                    <StaticImage layout={'fullWidth'}
                                                 src="../assets/custom-style/images/digi/_NG_8326_ACR.jpg"
                                                 alt={"Petr Bláha"}/>
                                    <figcaption className="figure-caption pt-1 text-center">Petr Bláha</figcaption>
                                </figure>
                            </div>
                            <div className="col-md">
                                <figure className="d-none d-md-block">
                                    <StaticImage layout={'fullWidth'}
                                                 src="../assets/custom-style/images/digi/_NG_8284_ACR-cropped.jpeg"
                                                 alt={"David Rangl"}/>
                                    <figcaption className="figure-caption pt-1">David Rangl</figcaption>
                                </figure>
                                <figure className="d-block d-md-none">
                                    <StaticImage layout={'fullWidth'}
                                                 src="../assets/custom-style/images/digi/_NG_8284_ACR.jpg"
                                                 alt={"David Rangl"}/>
                                    <figcaption className="figure-caption pt-1 text-center">David Rangl</figcaption>
                                </figure>
                            </div>
                            <div className="col-md">
                                <figure className="d-none d-md-block">
                                    <StaticImage layout={'fullWidth'}
                                                 src="../assets/custom-style/images/digi/_NG_8270_ACR-cropped.jpeg"
                                                 alt={"Jan Beneš"}/>
                                    <figcaption className="figure-caption pt-1">Jan Beneš</figcaption>
                                </figure>
                                <figure className="d-block d-md-none">
                                    <StaticImage layout={'fullWidth'}
                                                 src="../assets/custom-style/images/digi/_NG_8270_ACR.jpg"
                                                 alt={"Jan Beneš"}/>
                                    <figcaption className="figure-caption pt-1 text-center">Jan Beneš</figcaption>
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="nasiKlienti bgGrey pt-5 pb-5">
                    <div className="container pt-3 pb-5">
                        <h2 className="text-center mb-4">Mezi naše klienty patří</h2>
                        <div className="digiCards text-center pt-5">
                            <div className="row">
                                <div className="col-12 col-sm-6 col-lg-3 mb-4">
                                    <div className="card grow h-100">
                                        <div className="card-body">
                                            <StaticImage imgClassName={"srbaservis"}
                                                         src="../assets/custom-style/images/reference/srba.png"
                                                         alt={"SRBA SERVIS s.r.o."}/>
                                            <p className="card-text small">Prodej a servis vybraných značek osobních i
                                                užitkových vozidel již 30 let.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-3 mb-4">
                                    <div className="card grow h-100">
                                        <div className="card-body">
                                            <StaticImage imgClassName={"srbaservis"}
                                                         src="../assets/custom-style/images/reference/kralicek.png"
                                                         alt={"KRÁLÍČEK s.r.o."}/>
                                            <p className="card-text small">Výroba samolepících etiket a obalů pro
                                                potravinářské, kosmetické, průmyslové a další výrobky.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-3 mb-4">
                                    <div className="card grow h-100">
                                        <div className="card-body">
                                            <StaticImage imgClassName={"srbaservis"}
                                                         src="../assets/custom-style/images/reference/promotron.png"
                                                         alt={"PromoTron Solutions a.s."}/>
                                            <p className="card-text small">Inovujeme a digitalizujeme reklamní průmysl.
                                                Pro distributory, importéry, výrobce i tiskaře.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-3 mb-4">
                                    <div className="card grow h-100">
                                        <div className="card-body">
                                            <StaticImage imgClassName={"srbaservis"}
                                                         src="../assets/custom-style/images/reference/duha.png"
                                                         alt={"Společnost DUHA, z.ú."}/>
                                            <p className="card-text small">Sociální služby pro dospělé osoby s mentálním
                                                znevýhodněním.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-6 col-lg-3 mb-4">
                                    <div className="card grow h-100">
                                        <div className="card-body">
                                            <StaticImage imgClassName={"srbaservis"}
                                                         src="../assets/custom-style/images/reference/vubp.png"
                                                         alt={"Výzkumný ústav bezpečnosti práce, v. v. i."}/>
                                            <p className="card-text small">Aplikovaný výzkum se zaměřením na bezpečnost
                                                a ochranu zdraví při práci.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-3 mb-4">
                                    <div className="card grow h-100">
                                        <div className="card-body">
                                            <StaticImage imgClassName={"srbaservis"}
                                                         src="../assets/custom-style/images/reference/lehotsky.png"
                                                         alt={"LC alfa s.r.o."}/>
                                            <p className="card-text small">Dodavatel komplexních služeb v oboru
                                                energetiky a technologií pro inteligentní a energeticky efektivní
                                                budovy.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-3 mb-4">
                                    <div className="card grow h-100">
                                        <div className="card-body">
                                            <StaticImage imgClassName={"srbaservis"}
                                                         src="../assets/custom-style/images/reference/realism.png"
                                                         alt={"REALISM Development s.r.o."}/>
                                            <p className="card-text small">Realizujeme developerské projekty. Originální
                                                a ve všech směrech promyšlené.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-3 mb-4">
                                    <div className="card grow h-100">
                                        <div className="card-body">
                                            <StaticImage imgClassName={"srbaservis"}
                                                         src="../assets/custom-style/images/reference/greif.png"
                                                         alt={"Greif-akustika, s.r.o."}/>
                                            <p className="card-text small">Nezávislá společnost snižující hluk. Od
                                                studie a měření až po výrobu.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <Kontakt/>
        </Layout>
    );
}